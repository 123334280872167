<template>
  <div class="home_page h-full">
    <theHeader class="header-shadow" />
    <div class="pt-4 h-full">
      <router-view class="men md:mt-32 mt-24" />
    </div>
  </div>
</template>

<script>
import theHeader from '@/components/helper/header'

export default {
  name: 'Home',
  components: {
    theHeader
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/style/sass/variables";
.home_page {
  width: 1440px;
  margin-left: auto;
  margin-right: auto;
  background-color: #F5F5F5;
  height: 100%;
}

@media screen and (max-width: 1440px) {
  .home_page {
    background-color: #F5F5F5;
    width: 100%;
    height: 100%;
  }
  .home_page::-webkit-scrollbar {
    display: none;
  }
  .header-shadow {
    position: fixed;
  }
}
</style>
